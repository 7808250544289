import env from "@beam-australia/react-env";
import { accessTokenKey } from "@/constants/constants";
import utils from "@/utils/common";
import { statusCodeTextLookUp } from "@/utils/handleReturnedError";
import { NOT_FOUND_404 } from "@/constants/errors";


const apiQuery = async (
  url,
  controller = null,
  method,
  resolve,
  reject,
  data = null,
) => {
  try {
    const fetchPath = `${env("API_URL")}v${env("API_VERSION")}/${url}`;
    const fetchConfiguration = {
      method: method,
      timeout: 60000,
      signal: controller?.signal ? controller.signal : controller || null,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        accesstoken: utils.getFromLocalStorage(accessTokenKey),
      },
    };
    if (method.toUpperCase() !== "GET") {
      fetchConfiguration.body = JSON.stringify(data);
    }

    const response = await fetch(fetchPath, fetchConfiguration);
    let jsonData=null;
    if(response.status !== NOT_FOUND_404) {
       jsonData = await response.json();
    }
    
    if (response.ok) {
      resolve({
        statusCode: 200,
        data: jsonData,
        message: "OK",
      });
    } else {
      const err = new Error();
      err.status = response.status;
      err.title = statusCodeTextLookUp(jsonData?jsonData.statusCode:response.status);
      err.url = response.url;
      if (data) {
        err.message = jsonData.message;
        err.statusCodeValue = jsonData.statusCode;
        err.fieldName = jsonData.fieldName;
      }
      throw err;
    }
  } catch (error) {
    reject(error);
  }
};

export { apiQuery };
