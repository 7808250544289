import i18n from "@/configuration/i18n";
import {
  BAD_REQUEST_400,
  FORBIDDEN_403,
  INTERNAL_SERVER_ERROR_500,
  NOT_FOUND_404,
  UNAUTHORISED_401,
} from "@/constants/errors";

const handleReturnedError = (err, reject) => {
  reject(err);
};
const statusCodeTextLookUp = (status) => {
  let errorTitle;
  if (status === BAD_REQUEST_400) {
    errorTitle = "Bad Request";
  } else if (status === UNAUTHORISED_401) {
    errorTitle = "Unauthorised";
  } else if (status === FORBIDDEN_403) {
    errorTitle = "Forbidden";
  } else if (status === NOT_FOUND_404) {
    errorTitle = "Not Found";
  } else if (status === INTERNAL_SERVER_ERROR_500) {
    errorTitle = "Internal Server Error";
  }
  return errorTitle;
};
export { handleReturnedError, statusCodeTextLookUp };
