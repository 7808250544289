import { accessTokenKey, idTokenKey } from "@/constants/constants";

const getIdToken = () => {
  const tokenString = localStorage.getItem(idTokenKey);
  const userToken = JSON.parse(tokenString);
  return userToken;
};

const getAccessToken = () => {
  const tokenString = localStorage.getItem(accessTokenKey);
  // const userAccessToken = JSON.parse(tokenString);
  return tokenString;
};

const saveIdToken = (userToken) => {
  localStorage.setItem(idTokenKey, JSON.stringify(userToken));
};

const useToken = {
  getIdToken,
  saveIdToken,
  getAccessToken,
};

export default useToken;
